import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'

import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import LockIcon from '../icons/LockIcon'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import c from './GenerationFixedButton.module.scss'

interface GenerationFixedButtonProps {
  text: string
  headingMissing: string
  headingEntered: string
  headingLoader: string
  onProcessingStart: () => void
  isDisabled?: boolean
  isLockPremiumUser?: boolean
  isProcessing?: boolean
}

function GenerationFixedButton({
  text,
  headingMissing,
  headingEntered,
  headingLoader,
  onProcessingStart,
  isDisabled = false,
  isLockPremiumUser = false,
  isProcessing = false,
}: GenerationFixedButtonProps) {
  const { t } = useTranslation()
  const { data: user } = useFetchRequestMyProfile()

  const heading = text.length > 0 ? headingEntered : headingMissing

  const location = useLocation()

  const isDallE3PageWithoutSubscription =
    location.pathname.includes('dall-e-3') &&
    (!user || !user.is_paid_subscription)

  return (
    <div className={c.fixed}>
      <Button
        className={classNames({
          [c.button]: true,
          [c.buttonDisabledContent]: isDisabled,
        })}
        disabled={
          text.length <= 0 || isDisabled || isDallE3PageWithoutSubscription
        }
        onClick={onProcessingStart}
      >
        <div className={c.buttonContent}>
          {!isProcessing && heading}
          {isProcessing && headingLoader}
          {(text.length <= 0 ||
            isDisabled ||
            isDallE3PageWithoutSubscription) && (
            <LockIcon className={c.iconSetting} />
          )}
        </div>
      </Button>
    </div>
  )
}

export default GenerationFixedButton
