import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'

import TrendingIcon from '../icons/TrendingIcon.svg'
import FeedIcon from '../icons/FeedIcon.svg'

import c from './SwitcherPages.module.scss'

function SwitcherPages() {
  const { t } = useTranslation()

  const location = useLocation()

  const isActive = useCallback((path: string) => location.pathname === path, [])

  return (
    <div className={c.container}>
      <div className={c.content}>
        <ul className={c.listSwitchers}>
          <li
            className={`${c.itemSwitcher} ${
              isActive('/trending') || isActive('/') ? c.active : ''
            }`}
          >
            <div className={c.itemSwitcher__icon}>
              <img
                src={TrendingIcon}
                alt="trendingIcon"
                className={c.iconSetting}
              />
            </div>
            <div className={c.itemSwitcher__title}>
              {t('navigation.bestArt')}
            </div>
            <NavLink to="/trending" end className={c.link} />
          </li>
          <li
            className={`${c.itemSwitcher} ${
              isActive('/trending-video-art') ? c.active : ''
            }`}
          >
            <div className={c.itemSwitcher__icon}>
              <img
                src={TrendingIcon}
                alt="trendingIcon"
                className={c.iconSetting}
              />
            </div>
            <div className={c.itemSwitcher__title}>
              {t('navigation.bestVideo')}
            </div>
            <NavLink to="/trending-video-art" end className={c.link} />
          </li>
          <li
            className={`${c.itemSwitcher} ${isActive('/feed') ? c.active : ''}`}
          >
            <div className={c.itemSwitcher__icon}>
              <img
                src={FeedIcon}
                alt="trendingIcon"
                className={c.iconSetting}
              />
            </div>
            <div className={c.itemSwitcher__title}>{t('navigation.feed')}</div>
            <NavLink to="/feed" end className={c.link} />
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SwitcherPages
