import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Outlet, useNavigate } from 'react-router-dom'
import Processor from 'api/Retomagic'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchStyles from 'hooks/query/useFetchStyles'
import IdleStage from 'components/stages/dallE3/IdleStage'
import DallE3Strategy from 'api/Retomagic/Processor/Strategies/DallE3Strategy'

import ProcessingStage from 'components/stages/dallE3/ProcessingStage'
import { fixedResolutions } from 'constants/advancedSettings'

import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import { LimitError } from 'types/Response'

import c from './DallE3.module.scss'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import UnPaidSubscriptionContent from '../UnPaidSubscriptionContent'
import GenerationContainer, {
  AsideSlot,
  MainSlot,
} from '../GenerationControls/GenerationContainer/GenerationContainer'

function DallE3() {
  const dispatch = useAppDispatch()

  const { stage } = useAppSelector((state) => state.stage)

  const navigate = useNavigate()

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const { data: stylesData } = useFetchStyles()

  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)

  const [chosenStyle, setChosenStyle] = useState<string | undefined>('')

  const [seconds, setSeconds] = useState<number | undefined>(0)
  const [text, setText] = useState<string>('')
  const [resolution, setResolution] = useState<number>(1)

  const { data: user } = useFetchRequestMyProfile()

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/dall-e-3/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    try {
      dispatch(changeStage('processing'))

      const thisStyle = stylesData.find((elem) => elem.id === chosenStyle)

      const strategy = new DallE3Strategy(
        text,
        thisStyle?.title,
        thisStyle?.text,
        fixedResolutions[resolution].width,
        fixedResolutions[resolution].height,
        undefined,
      )

      const processor = new Processor(strategy)
      processor.on(
        ProcessorEvents.PROCESSED_ASSETS_CHANGED,
        handleProcessedAssetsChanged,
      )

      await processor.start()
      setSeconds(strategy.getSeconds())
      setGenerationProcessor(processor)
    } catch (error) {
      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        setText('')
        setChosenStyle('')
        dispatch(changeStage('idle'))
      }
      console.log(error)
    }
  }, [text, handleProcessedAssetsChanged, chosenStyle, stylesData, resolution])

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  useEffect(() => {
    if (stage !== 'idle') return
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
  }, [stage])

  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  useEffect(() => {
    return () => {
      if (stage !== 'processing') {
        dispatch(changeProcessedAssets([]))
        dispatch(changeCurrentAssetIndex(0))
        setGenerationProcessor(null)
        generationProcessor?.stop()
      }
    }
  }, [generationProcessor, stage])

  return (
    <GenerationContainer stage={stage}>
      <MainSlot>
        {stage === 'processing' && seconds && (
          <div className={c.processing}>
            <ProcessingStage seconds={seconds} />
          </div>
        )}
        {user && !user.is_paid_subscription && stage === 'idle' && (
          <UnPaidSubscriptionContent />
        )}
        {stage === 'idle' && <Outlet />}
      </MainSlot>
      <AsideSlot>
        <IdleStage
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          text={text}
          onExampleClick={onExampleClick}
          onTextChange={setText}
          onProcessingStart={handleProcessingStart}
          onStyleClick={onStyleClick}
          chosenStyle={chosenStyle}
          styles={stylesData}
          resolution={resolution}
          setResolution={setResolution}
        />
      </AsideSlot>
    </GenerationContainer>
  )
}

export default DallE3
