import React from 'react'
import AddImage from 'components/AddImage'
import Handlers from 'types/Handlers'

import c from './EnhancementHistory.module.scss'

interface EnhancementHistoryProps
  extends React.HTMLAttributes<HTMLInputElement> {
  handler?: `${Handlers}`
  isDeleting?: boolean
  selectToDelete?: (id: number) => void
  selectedToDelete?: number[]
}

function EnhancementHistory({
  handler = 'Thomas_Jefferson',
  ...props
}: EnhancementHistoryProps) {
  return (
    <div className={c.addImageWrapper}>
      <AddImage
        className={c.addImage}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  )
}

export default EnhancementHistory
