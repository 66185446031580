import { useQuery } from '@tanstack/react-query'
import { getRequestById } from 'api'
import { useAppSelector } from 'hooks/store'
import { BaseResponse } from 'types/Response'

interface Params {
  requestId: string | undefined
}

const useFetchRequest = ({ requestId }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, requestId]

  return useQuery<BaseResponse, Error>(
    ['request', deps],
    () => {
      if (requestId) {
        const numberRequestId = parseInt(requestId, 10)
        return getRequestById(numberRequestId)
      }
      return Promise.reject(new Error('Invalid request ID'))
    },
    {
      refetchInterval(data, query) {
        if (data?.assets?.some((elem) => elem.status === 'pending')) {
          return 3000
        }
        return false
      },
    },
  )
}

export default useFetchRequest
