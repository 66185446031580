import React, { useRef } from 'react'
import AddImage from 'components/AddImage'
import { useTranslation } from 'react-i18next'
import ScrollContainer from 'react-indiana-drag-scroll'
import copy from 'clipboard-copy'

import s from './MixerInputs.module.scss'

interface MixerInputsProps {
  text: string
  imageFiles: (File | null)[] | null
  onImageChange: (index: number, e: React.ChangeEvent<HTMLInputElement>) => void
  getImageUrl: (file: File | null) => string | null
  buttonState: {
    button0: boolean
    button1: boolean
    button2: boolean
  }
  onDeleteImage: (index: number) => void
  onTextChange: (newText: string) => void
  onCursor: (s: string) => void
}

function MixerInputs({
  text,
  buttonState,
  onTextChange,
  imageFiles,
  getImageUrl,
  onImageChange,
  onDeleteImage,
  onCursor,
}: MixerInputsProps) {
  if (!imageFiles) return null

  const { t } = useTranslation()
  const handleTextTransform = (number: string) => {
    copy(` #${number} `)
    onCursor(` #${number} `)
  }

  function isButtonDisabled(
    index: number,
    buttonStates: {
      button0: boolean
      button1: boolean
      button2: boolean
    },
  ): boolean {
    switch (index) {
      case 0:
        return buttonStates.button0
      case 1:
        return buttonStates.button1
      case 2:
        return buttonStates.button2
      default:
        return true
    }
  }

  return (
    <div className={s.photoSelection}>
      <p className={s.heading}>{t('stages.mixer.idle.heading')}</p>
      <ScrollContainer className={s.historyListWrapper}>
        <ul className={s.photoSelection__list}>
          {imageFiles.map((file, index) => (
            <li
              key={file ? `image-${index}-${file.name}` : `image-${index}`}
              className={s.photoSelection__item}
            >
              <AddImage
                imageUrl={file ? getImageUrl(file) : null}
                onChange={(e) => onImageChange(index, e)}
                index={index}
                onDeleteImage={() => onDeleteImage(index)}
                buttonText={`${t('stages.mixer.idle.add')} #${index + 1}`}
                onButtonClick={() => handleTextTransform(`${index + 1}`)}
                isButtonDisabled={isButtonDisabled(index, buttonState)}
              />
            </li>
          ))}
        </ul>
      </ScrollContainer>
    </div>
  )
}

export default MixerInputs
