/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-alert */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { NavLink } from 'react-router-dom'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'

import { useTranslation } from 'react-i18next'

import VerifiedIcon from 'components/VerifiedIcon'

import { toast } from 'react-toastify'

import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import useModal from 'hooks/useModal'
import { setLogInModalOpen } from 'store/reducers/modalReducer'
// import { unlike } from 'api'

import {
  adminDeleteEverywhere,
  likeRequest,
  unlikeRequest,
  deletePost,
  adminHidePost,
  adminUnHidePost,
  followRequest,
  unFollowRequest,
} from 'api'
import ReactTimeAgo from 'react-time-ago'
import { PostFeed } from 'types/Post'
import IconButton from 'components/common/IconButton'
import useCopy from 'hooks/useCopy'
import { useAppSelector } from 'hooks/store'

import { ReactComponent as ArrowIcon } from '@material-design-icons/svg/round/expand_more.svg'

import UnLikeIcon from 'components/icons/UnLikeIcon'
import LikeIcon from 'components/icons/LikeIcon'
import CopyIcon from 'components/icons/CopyIcon'
import ViewsIcon from 'components/icons/ViewsIcon'

import ArrowUp from 'components/icons/ArrowUp'
import ArrowDown from 'components/icons/ArrowDown'
import SettingsVideoPost from 'components/SettingsVideoPost'
import PlayIcon from 'components/icons/PlayIcon'
import { useSwiper } from 'swiper/react'

import VolumeSetting from 'components/VolumeSetting'

import c from './TrendingVideoPost.module.scss'
import SettingsPost from "../SettingsPost";

interface PostProps {
  post: PostFeed
  imgClassName?: string
  withLink?: boolean
  trending?: boolean
  feed?: boolean
  setMuted: () => void
  slideIndex: number
  volume: number
  activeIndex: number
}

function TrendingVideoPost({
  post,
  imgClassName = '',
  withLink = false,
  trending = false,
  feed = false,
  slideIndex,
  volume,
  setMuted,
  activeIndex,
}: PostProps) {
  const { t } = useTranslation()
  const language = useAppSelector((state) => state.settings.language)
  const [isLiked, setIsLiked] = useState<boolean>(post.is_liked)
  const [likesCount, setLikesCount] = useState<number>(post.likes_count)
  const { isCopied, handleCopy } = useCopy(post.assets?.[0]?.source)
  const videoRef = useRef<HTMLVideoElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [showButton, setShowButton] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);

  const getWidthContainer = () => {
    const containerEl = containerRef.current

    if(containerEl) {
      const {height} = containerEl.getBoundingClientRect()
      return (height / 100) * 60
    }

    return 350
  }

  const [width, setWidth] = useState<number>(350)

  const [isPlaying, setIsPlaying] = useState<boolean>(
    slideIndex === activeIndex,
  )

  const handleVolumeChange = (newVolume: number) => {
    if (videoRef.current) {
      videoRef.current.volume = newVolume
    }
  }

  const truncateText = (text:string) => {
    return text.length > 100 ? `${text.substring(0, 100)}... ` : text
  }

  useEffect(() => {
    handleVolumeChange(volume)
  }, [volume])

  useEffect(() => {
    const containerEl = containerRef.current
    if(containerEl) {
      window.addEventListener('resize', () => {
        setWidth(getWidthContainer())
      })

      setWidth(getWidthContainer())
    }
  }, [])

  const swiper = useSwiper()

  useEffect(() => {
    if (videoRef?.current) {
      const videoEl = videoRef.current
      if (slideIndex === activeIndex) {
        videoEl.play()
        setIsPlaying(false)
      } else {
        videoEl.pause()
      }
    }
  }, [activeIndex])
  const { data: user } = useFetchRequestMyProfile()

  const [isFollowed, setIsFollowed] = useState(post.user?.is_subscribed)
  const mutationLike = useMutation<void, Error>(() => {
    return likeRequest(post.id!)
  })
  const mutationUnLike = useMutation<void, Error>(() => {
    return unlikeRequest(post.id!)
  })
  const mutationDeleteByAdmin = useMutation<void, Error>(() => {
    return adminDeleteEverywhere(post.id!)
  })
  const mutationHide = useMutation<void, Error>(() => {
    return adminHidePost(post.id!)
  })
  const mutationUnhide = useMutation<void, Error>(() => {
    return adminUnHidePost(post.id!)
  })
  const mutationDelete = useMutation<void, Error>(() => {
    return deletePost(post.id!)
  })

  const { logInModalOpen } = useAppSelector((state) => state.modal)
  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon className={c.icon} style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon />
    )
  }, [isCopied])

  const mutationFollow = useMutation<void, Error>(() => {
    return followRequest(post.user.id)
  })
  const mutationUnFollow = useMutation<void, Error>(() => {
    return unFollowRequest(post.user.id!)
  })

  const toggleText = useCallback(() => {
    setIsExpanded((prev) => !prev);
  },[]);

  const likeHandler = () => {
    if (user?.id !== post.user.id) {
      if (isLiked) {
        setLikesCount((prev) => prev - 1)
        setIsLiked(false)
        mutationUnLike.mutate()
      } else {
        setLikesCount((prev) => prev + 1)
        setIsLiked(true)
        mutationLike.mutate()
      }
    } else {
      toast.error("You can't like your own post")
    }
  }

  const deleteEverywhere = () => {
    const result = window.confirm('Are you sure want to delete this post?')
    if (result) {
      mutationDeleteByAdmin.mutate()
      toast.success('Everything went well')
    }
  }

  const deleteYourPost = () => {
    const result = window.confirm('Are you sure want to delete this post?')
    if (result) {
      mutationDelete.mutate()
      toast.success('Everything went well')
    }
  }

  const hideTrends = () => {
    const result = window.confirm('Are you sure want to hide this post?')
    if (result) {
      mutationHide.mutate()
      toast.success('Everything went well')
    }
  }

  const unhideTrends = () => {
    const result = window.confirm('Are you sure want to unhide this post?')
    if (result) {
      mutationUnhide.mutate()
      toast.success('Everything went well')
    }
  }

  const { data: myProfileData } = useFetchRequestMyProfile()

  const isMyProfile = useMemo(
    () => post.user.nickname === myProfileData?.nickname,
    [post.user.nickname, myProfileData?.nickname],
  )

  const followHandler = () => {
    if (isFollowed) {
      setIsFollowed(false)
      mutationUnFollow.mutate()
    } else {
      setIsFollowed(true)
      mutationFollow.mutate()
    }
  }

  const toggleVideo = useCallback(
    (e: React.MouseEvent<HTMLVideoElement>) => {
      setIsPlaying((prev) => !prev)
      if (isPlaying) {
        e.currentTarget.play()
      } else {
        e.currentTarget.pause()
      }
    },
    [isPlaying],
  )

  const toggleVideoStart = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const videoEl = e.currentTarget.parentElement?.querySelector('video')
      if (videoEl) {
        if (isPlaying) {
          videoEl.play()
        } else {
          videoEl.pause()
        }
      }
    },
    [isPlaying],
  )

  useEffect(() => {
    if (textRef.current) {
      const isOverflowing = textRef.current.scrollHeight > textRef.current.clientHeight;
      setShowButton(isOverflowing);
    }
  }, [post]);

  return (
    <div className={c.post}>
      <div className={c.firstColumn}>
        <button
          type="button"
          onClick={() => swiper.slidePrev()}
          className={c.navButtonPrev}
        >
          <ArrowDown />
        </button>
        <button
          type="button"
          onClick={() => swiper.slideNext()}
          className={c.navButtonNext}
        >
          <ArrowUp />
        </button>
      </div>
      <div className={c.secondColumn} ref={containerRef} style={{
        width: `${width}px`
      }}>
        <div
          className={`${
            trending || post.assets?.[0].source_type === 'video'
              ? c.postImgWrapperTrending
              : c.postImgWrapper
          } ${imgClassName}`}
        >
          {post.assets?.[0].source_type === 'video' && post.assets?.[0].url && (
            <>
              <div className={c.videoContainer}>
                {isPlaying && (
                  <button
                    type="button"
                    className={c.statusVideo}
                    onClick={toggleVideoStart}
                  >
                    <PlayIcon className={c.playButton} />
                  </button>
                )}
                <video
                  ref={videoRef}
                  src={post.assets?.[0].url}
                  className={c.postImg}
                  onClick={toggleVideo}
                  loop
                />
              </div>
              <div className={c.postBottom}>
                <div className={c.postWrapper}>
                  <div className={c.postUser}>
                    <div className={c.avatarWrapper}>
                      {post.user.avatar && (
                        <img
                          className={c.avatar}
                          src={post.user.avatar}
                          alt=""
                        />
                      )}
                    </div>
                    <div className={c.nicknameWrapper}>
                      <span className={c.userInfo}>
                        <NavLink
                          className={c.link}
                          to={`/profile/${post.user.nickname}`}
                          end
                        >
                          <p className={c.nickname}>
                            {post.user.nickname}{' '}
                            {post.user.is_paid_subscription && <VerifiedIcon />}
                          </p>
                          {post.created_at && (
                            <p className={c.date}>
                              <ReactTimeAgo
                                date={new Date(post.created_at)}
                                locale={language === 'en' ? 'en-US' : 'ru'}
                              />
                            </p>
                          )}
                        </NavLink>
                      </span>
                      <div className={c.followButton}>
                        {!isMyProfile && (
                          <button
                            type="button"
                            className={`${c.follow} ${
                              isFollowed ? c.followed : ''
                            } ${c.followMobile}`}
                            onClick={followHandler}
                          >
                            {mutationFollow.isLoading ? (
                              t('general.loading')
                            ) : (
                              <>
                                {isFollowed && t('profile.unFollow')}
                                {!isFollowed && t('profile.follow')}
                              </>
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={c.descriptionContainer}>

                    <div className={c.text}>
                      <span>{isExpanded ? post.assets?.[0]?.source : truncateText(post.assets?.[0]?.source)}</span>
                      {post.assets?.[0]?.source.length > 100 &&
                          <button type="button"  className={c.buttonTextContainer} onClick={toggleText}>
                            {isExpanded ?  <span className={c.buttonText}>{t("general.post.hideText")}</span> : <span className={c.buttonText}>{t("general.post.showText")}</span>}
                          </button>
                      }
                    </div>

                    <IconButton
                      className={c.postCopy}
                      disabled={isCopied}
                      onClick={handleCopy}
                    >
                      {copyIcon}
                    </IconButton>
                  </div>
                  <div className={c.postTags}>
                    {post.assets?.[0]?.style &&
                      post.assets?.[0]?.style !== 'undefined' && (
                        <div className={c.textStyleContainer}>
                          <span className={c.textStyle}>
                            {post.assets?.[0]?.style}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={c.thirdColumn}>
        <div className={c.postFeedBack}>
          <div
            className={c.postLikes}
            onClick={!user?.id ? openLogInModal : likeHandler}
            onKeyDown={!user?.id ? openLogInModal : likeHandler}
            role="button"
            tabIndex={0}
          >
            <div className={c.postLikesIcon}>
              {isLiked ? (
                <LikeIcon className={c.iconSetting} />
              ) : (
                <UnLikeIcon className={c.iconSetting} />
              )}
            </div>
            <p className={c.postLikesCount}>{likesCount}</p>
          </div>
          <div className={c.volumeSliderContainer}>
            <VolumeSetting setMuted={setMuted} volume={volume} />
          </div>
          {(user?.role === 'admin' || post.user.id === user?.id)
              && (
                  <div className={c.settingsPost}>
                    <SettingsPost
                        userData={user}
                        postData={post}
                        delete={deleteYourPost}
                        unhide={unhideTrends}
                        hide={hideTrends}
                        deleteByAdmin={deleteEverywhere}
                    />
                  </div>
              )}
          <div className={c.postViews}>
            <ViewsIcon />
            {`${post.views_count} `}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrendingVideoPost
