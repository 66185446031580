import React, { useCallback, useEffect, useState, useRef } from 'react'

import { Outlet, useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'hooks/store'
import IdleStage from 'components/stages/mixer/IdleStage'
import ProcessingStage from 'components/stages/mixer/ProcessingStage'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import useFetchStyles from 'hooks/query/useFetchStyles'
import useFetchSuggestionsForMixer from 'hooks/query/useFetchSuggestionsForMixer'
import Processor, { MixerStrategy } from 'api/Retomagic'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import { LimitError } from 'types/Response'

import c from './NeuroMixer.module.scss'
import GenerationContainer, {
  AsideSlot,
  MainSlot,
} from '../GenerationControls/GenerationContainer/GenerationContainer'

function NeuroMixer() {
  const { stage } = useAppSelector((state) => state.stage)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { data: stylesData } = useFetchStyles()

  const { data: suggestionsData } = useFetchSuggestionsForMixer()
  const [seconds, setSeconds] = useState<number | undefined>(0)
  const [chosenStyle, setChosenStyle] = useState<string | undefined>('')
  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const [text, setText] = useState<string>('')
  const [imageFiles, setImageFiles] = useState<Array<File | null>>([
    null,
    null,
    null,
  ])

  const isButtonDisabled = useCallback(() => {
    const usedHashTags = (text.match(/#\d+/g) || []) as string[]

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < imageFiles.length; i++) {
      if (imageFiles[i] && !usedHashTags.includes(`#${i + 1}`)) {
        return true
      }
    }

    if (usedHashTags.length !== imageFiles.filter(Boolean).length) {
      return true
    }

    return false
  }, [text, imageFiles])

  const getImageUrl = useCallback((file: File | null) => {
    return file ? URL.createObjectURL(file) : null
  }, [])

  const onImageChange = useCallback(
    (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (file) {
        setImageFiles((prevFiles) => {
          const newFiles = prevFiles.map((prevFile, i) => {
            if (i === index) {
              return file
            }
            return prevFile
          })
          return newFiles
        })
      }
    },
    [],
  )

  const onDeleteImage = useCallback((index: number) => {
    setImageFiles((prevFiles) => {
      const newFiles = prevFiles.map((prevFile, i) => {
        if (i === index) {
          return null
        }
        return prevFile
      })
      return newFiles
    })
  }, [])

  const [buttonState, setButtonState] = useState({
    button0: false,
    button1: false,
    button2: false,
  })

  useEffect(() => {
    setButtonState({
      button0: text.includes('#1'),
      button1: text.includes('#2'),
      button2: text.includes('#3'),
    })
  }, [text])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/ai-avatars/finish/${id}`)
      }
    },
    [],
  )

  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    try {
      dispatch(changeStage('processing'))

      const thisStyle = stylesData.find((elem) => elem.id === chosenStyle)

      const strategy = new MixerStrategy({
        text,
        image: imageFiles[0]!,
        styleTitle: thisStyle?.title,
        styleText: thisStyle?.text,
        image2: imageFiles[1]!,
        image3: imageFiles[2]!,
        requestId: undefined,
      })

      const processor = new Processor(strategy)
      processor.on(
        ProcessorEvents.PROCESSED_ASSETS_CHANGED,
        handleProcessedAssetsChanged,
      )

      await processor.start()
      setSeconds(strategy.getSeconds())
      setGenerationProcessor(processor)
    } catch (error) {
      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        setText('')
        setChosenStyle('')
        setImageFiles([null, null, null])
        dispatch(changeStage('idle'))
      }
    }
  }, [text, handleProcessedAssetsChanged, chosenStyle, stylesData, imageFiles])

  useEffect(() => {
    return () => {
      if (stage !== 'processing') {
        dispatch(changeProcessedAssets([]))
        dispatch(changeCurrentAssetIndex(0))
        generationProcessor?.stop()
        setGenerationProcessor(null)
      }
    }
  }, [generationProcessor, stage])

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const handleCursorChange = useCallback((str: string) => {
    if (inputRef.current) {
      const { selectionStart } = inputRef.current
      setText((prev) => {
        const stringArray = prev.split('')
        stringArray.splice(selectionStart!, 0, str)
        const modifiedString = stringArray.join('')

        return modifiedString
      })
    }
  }, [])

  return (
    <GenerationContainer stage={stage}>
      <MainSlot>
        {stage === 'processing' && seconds && (
          <div className={c.processing}>
            <ProcessingStage seconds={seconds} />
          </div>
        )}
        <Outlet />
      </MainSlot>
      <AsideSlot>
        <IdleStage
          text={text}
          imageFiles={imageFiles}
          buttonState={buttonState}
          inputRef={inputRef}
          onCursor={handleCursorChange}
          isImage={isButtonDisabled()}
          getImageUrl={getImageUrl}
          onTextChange={setText}
          onProcessingStart={handleProcessingStart}
          suggestions={suggestionsData}
          onExampleClick={onExampleClick}
          onStyleClick={onStyleClick}
          onImageChange={onImageChange}
          chosenStyle={chosenStyle}
          styles={stylesData}
          onDeleteImage={onDeleteImage}
        />
      </AsideSlot>
    </GenerationContainer>
  )
}

export default NeuroMixer
