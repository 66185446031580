
import React, { useMemo, useEffect, useState, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import { useMutation } from '@tanstack/react-query'
import TrendingPost from 'components/TrendingPost'

import {
  pushViewedPosts,
  synchronizeArrays,
} from 'store/reducers/viewedReducer'
import { sendViewedArray } from 'api'

import Container from 'components/common/Container'
import Loader from 'components/common/Loader'
import SwitcherPages from 'components/SwitcherPages'

import { useTranslation } from 'react-i18next'
import useInfiniteFetchPosts from 'hooks/query/useInfiniteFetchPosts'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import MobileHeader from '../../components/MobileHeader'

import c from './TrendingPage.module.scss'

export interface ILightbox {
  id: number | null
  state: boolean
}

function TrendingPage() {
  const [isInitialRender, setIsInitialRender] = useState(true)
  const { freezedExceptions } = useAppSelector((state) => state.viewed)

  const [isLightboxOpen, setIsLightboxOpen] = useState<ILightbox>({
    id: null,
    state: false,
  })

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [viewedPosts, setViewedPosts] = useState<number[]>([])

  const mutationView = useMutation<void, Error>(() => {
    return sendViewedArray(viewedPosts)
  })

  const exceptionsRestructured = useMemo(
    () => freezedExceptions.map((elem) => elem.id),
    [freezedExceptions],
  )

  const {
    data: infinitePostsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchPosts({
    take: 6,
    type: 'trending',
    conclusion: 'images_only',
    exceptions: exceptionsRestructured,
    enabled: !isInitialRender,
  })

  const postsData = useMemo(() => {
    if (infinitePostsData === undefined) return []
    return infinitePostsData.pages.flatMap((p) => p)
  }, [infinitePostsData])

  const onScrollToEnd = () => {
    if (isLoading) return
    fetchNextPage()
  }

  const viewHandle = (inView: boolean, postId: number) => {
    if (inView) {
      setViewedPosts((prev) => [...prev, postId])
    }
  }

  useEffect(() => {
    if (viewedPosts.length === 3) {
      mutationView.mutate()
      dispatch(pushViewedPosts(viewedPosts))
      setViewedPosts([])
    }
  }, [viewedPosts])

  useEffect(() => {
    setIsInitialRender(false)
    dispatch(synchronizeArrays())
  }, [])

  return (
    <div className={c.feedPage}>
      <MobileHeader heading={t('navigation.bestArt')} isOnGoBack />
      <Container flex noPaddingOnMobile>
        <div className={c.content}>
          <div className={c.switch}>
            <SwitcherPages />
          </div>
          <div className={c.feed}>
            <div className={c.columnsContainer}>
              {postsData.map((post) => (
                <div
                  className={c.wrapper}
                  key={post.id}
                  style={{
                    zIndex:
                      post.id === isLightboxOpen.id && isLightboxOpen.state
                        ? 1000
                        : 'auto',
                  }}
                >
                  <TrendingPost
                    post={post}
                    isLightboxOpen={isLightboxOpen}
                    setIsLightboxOpen={setIsLightboxOpen}
                    trending
                  />
                  <InView
                    as="div"
                    onChange={(inView) => viewHandle(inView, post.id)}
                    triggerOnce
                  />
                </div>
              ))}
            </div>
            {!isLoading && !isFetchingNextPage && hasNextPage && ( 
              <InView 
                as="div" 
                onChange={(inView) => inView && onScrollToEnd()} 
              /> 
            )} 
            {isFetchingNextPage && ( 
              <div className={c.loaderWrapper}> 
                <div className={c.loader}> 
                  <Loader /> 
                </div> 
              </div> 
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TrendingPage
