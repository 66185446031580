import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PostFeed } from 'types/Post'
import { UserResponse } from 'types/User'
import { ReactComponent as SettingsIcon } from '@material-design-icons/svg/round/more_horiz.svg'

import { useTranslation } from 'react-i18next'
import i18n from 'i18n/config'

import c from './SettingsPost.module.scss'

interface SettingsPostProps {
  postData: PostFeed
  userData: UserResponse | null | undefined
  delete: () => void
  unhide: () => void
  hide: () => void
  deleteByAdmin: () => void
}

function SettingsPost({
  userData,
  postData,
  delete: deletePost,
  unhide,
  hide,
  deleteByAdmin,
}: SettingsPostProps) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const settingsRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      settingsRef.current &&
      !settingsRef.current.contains(event.target as Node)
    ) {
      setIsSettingsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const downloadImage = () => {
    const link = document.createElement('a')
    link.href = postData.assets?.[0].url
    link.target = '_blank'
    link.download = 'image'
    document.body.appendChild(link) //
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div
      className={c.container}
      role="button"
      onClick={toggleSettings}
      onKeyPress={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          toggleSettings()
        }
      }}
      style={
        isSettingsOpen
          ? { backgroundColor: '#282631', borderRadius: '200px' }
          : {}
      }
      ref={settingsRef}
      tabIndex={0}
    >
      <div className={c.content}>
        <div className={c.iconWrapper}>
          <SettingsIcon fill="#D1D1D6" className={c.settingsIcon} />
        </div>
        {isSettingsOpen && (
          <div className={c.settingsWrapper}>
            <div className={c.settingsWindow}>
              <div className={c.actions}>
                <div className={c.admin}>
                  <button
                      type="button"
                      className={c.downloadAction}
                      onClick={downloadImage}
                  >
                    {t('general.post.download')}
                  </button>
                </div>
                {postData.user.id === userData?.id && (
                  <div className={c.admin}>
                    <button
                      type="button"
                      className={c.deleteAction}
                      onClick={deletePost}
                    >
                      {t('profile.delete')}
                    </button>
                  </div>
                )}
                {userData?.role === 'admin' && (
                  <button
                    type="button"
                    className={c.deleteAction}
                    onClick={deleteByAdmin}
                  >
                    <span>{`${i18n.t('general.post.deletePost')}`}</span>
                  </button>
                )}

                {userData?.role === 'admin' &&
                  (postData.user.is_trending_blocked ||
                  postData.is_trending_blocked ? (
                    <button
                      className={c.hideAction}
                      onClick={unhide}
                      type="button"
                    >
                      <span>{`${i18n.t('general.post.unhidePost')}`}</span>
                    </button>
                  ) : (
                    <button
                      className={c.hideAction}
                      onClick={hide}
                      type="button"
                    >
                      <span>{`${i18n.t('general.post.hidePost')}`}</span>
                    </button>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SettingsPost
