import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import TextInput from 'components/common/TextInput'
import classNames from 'classnames'
import c from './AuthForm.module.scss'
import SocialAuth from '../SocialAuth'
import TextArea from '../common/TextArea'

interface AuthFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  email?: string
  password?: string
  nickname?: string
  userName?: string
  description?: string
  submitText: string
  screen?: string
  isSubmitting: boolean
  formType?: 'logIn' | 'signUp'
  onEmailChange?: (newEmail: string) => void
  onPasswordChange?: (newPassword: string) => void
  onNicknameChange?: (newEmail: string) => void
  onUserNameChange?: (newPassword: string) => void
  onDescriptionChange?: (newPassword: string) => void
  onForgotPassword?: () => void
  onClose?: () => void
  handleLogOut?: () => void
}

function AuthForm({
  className,
  onClose = () => {},
  email = undefined,
  password = undefined,
  submitText,
  isSubmitting,
  screen = '',
  nickname = undefined,
  userName = undefined,
  description = undefined,
  formType = undefined,
  onEmailChange = undefined,
  onPasswordChange = undefined,
  onForgotPassword = undefined,
  onNicknameChange = undefined,
  onUserNameChange = undefined,
  onDescriptionChange = undefined,
  handleLogOut = undefined,
  ...props
}: AuthFormProps) {
  const { t } = useTranslation()

  const [isMobile, setIsMobile] = useState<boolean>(false)

  const classNamess = useMemo(() => {
    let result = c.form
    if (className) result += ` ${className}`
    return result
  }, [className])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onEmailChange) onEmailChange(e.target.value)
    },
    [onEmailChange],
  )

  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onPasswordChange) onPasswordChange(e.target.value)
    },
    [onPasswordChange],
  )
  const handleUserNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onUserNameChange) onUserNameChange(e.target.value)
    },
    [onUserNameChange],
  )
  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onDescriptionChange) onDescriptionChange(e.target.value)
    },
    [onDescriptionChange],
  )
  const handleNicknameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onNicknameChange) onNicknameChange(e.target.value)
    },
    [onNicknameChange],
  )

  return (
    <form className={classNamess} {...props}>
      {nickname != null &&
        userName != null &&
        description != null &&
        !isMobile && <span className={c.header}>Continue registration</span>}
      {nickname != null && (
        <TextInput
          className={c.formInput}
          required
          type="nickname"
          placeholder={t('general.formPlaceholders.nickname')}
          value={nickname}
          onChange={handleNicknameChange}
        />
      )}
      {userName != null && (
        <TextInput
          className={c.formInput}
          required
          type="name"
          placeholder={t('general.formPlaceholders.userName')}
          value={userName}
          onChange={handleUserNameChange}
        />
      )}
      {description != null && (
        <TextArea
          className={c.formArea}
          placeholder={t('general.formPlaceholders.description')}
          value={description}
          onChange={handleDescriptionChange}
        />
      )}
      {email != null && (
        <TextInput
          className={c.formInput}
          required
          type="email"
          placeholder={t('general.formPlaceholders.email')}
          value={email}
          onChange={handleEmailChange}
        />
      )}
      {password != null && (
        <TextInput
          className={c.formInput}
          required
          type="password"
          placeholder={t('general.formPlaceholders.password')}
          value={password}
          onChange={handlePasswordChange}
        />
      )}
      <div className={c.buttons}>
        {screen !== 'forgot-password' && screen !== 'userModal' && (
          <div className={c.wrapper}>
            <span className={c.socialHeading}>
              {t('modals.general.socialWays')}
            </span>
            {onForgotPassword && (
              <button
                className={c.formForgotPassword}
                type="button"
                onClick={onForgotPassword}
              >
                {t('modals.logIn.forgotPassword')}
              </button>
            )}
          </div>
        )}
        {screen !== 'forgot-password' && screen !== 'userModal' && (
          <SocialAuth onSuccess={onClose} />
        )}
      </div>
      <Button
        className={classNames({
          [c.formSubmit]: true,
          [c.formSubm]: description != null,
        })}
        type="submit"
        disabled={isSubmitting}
      >
        {submitText}
      </Button>
    </form>
  )
}

export default AuthForm
