/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'

import Button from '../common/Button'
import DownloadIcon from '../icons/DownloadIcon'
import c from './AddImage.module.scss'

interface AddImageProps extends React.HTMLAttributes<HTMLInputElement> {
  imageUrl?: string | null | undefined
  onDeleteImage?: (index: number) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  index?: number
  buttonText?: string
  chosenHistory?: number
  onButtonClick?: () => void
  isButtonDisabled?: boolean
  handleHistoryClick?: (id: number | undefined) => void
}

function AddImage({
  buttonText = '',
  onChange = (e) => e,
  imageUrl: initialImageUrl = null,
  className,
  onDeleteImage = undefined,
  index = 0,
  onButtonClick = () => {},
  isButtonDisabled = false,
  handleHistoryClick = undefined,
  chosenHistory = undefined,
  ...props
}: AddImageProps) {
  const [imageUrl, setImageUrl] = useState<string | null | undefined>(
    initialImageUrl,
  )
  const fileInput = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0]
      setImageUrl(URL.createObjectURL(file))
      const event = {
        target: {
          files: e.dataTransfer.files,
        },
      } as React.ChangeEvent<HTMLInputElement>

      // Вызываем onChange с искусственным событием
      onChange(event)
    }
  }

  const deleteImage = () => {
    if (fileInput?.current?.value) {
      fileInput.current.value = ''
    }
    setImageUrl(null)

    if (onDeleteImage) onDeleteImage(index)
  }

  const onButtonClickfunc = () => {
    fileInput.current?.click()
  }

  useEffect(() => {
    if (imageUrl && fileInput.current?.files) {
      const fakeEvent = {
        target: {
          files: fileInput.current.files,
          value: fileInput.current.value,
        },
      }
      onChange(fakeEvent as unknown as React.ChangeEvent<HTMLInputElement>)
    }
  }, [imageUrl, onChange])

  return (
    <div className={c.addImage} onDragOver={handleDragOver} onDrop={handleDrop}>
      <div className={c.content}>
        <div className={c.tip}>
          <DownloadIcon />
          <span className={c.text}>
            {t('stages.enhancement.idle.dragDrop')}
          </span>
          <span>{t('stages.generation.idle.or')}</span>
        </div>
        {imageUrl && (
          <div
            className={classNames({
              [c.imageWrapper]: true,
              [c.active]: handleHistoryClick && chosenHistory === undefined,
            })}
            onClick={() => handleHistoryClick && handleHistoryClick(undefined)}
          >
            <img src={imageUrl} className={c.image} alt="" />
            <div className={c.deleteImageContainer}>
              <CloseIcon className={c.deleteImage} onClick={deleteImage} />
            </div>

            {buttonText && imageUrl && (
              <Button
                variant="contained"
                className={c.addImageNumber}
                onClick={onButtonClick}
                disabled={isButtonDisabled}
              >
                {buttonText}
              </Button>
            )}
          </div>
        )}
        <Button
          variant="outlined"
          onClick={onButtonClickfunc}
          className={c.uploadButton}
        >
          {t('stages.enhancement.idle.buttonText')}
        </Button>
        <input
          type="file"
          accept="image/*"
          className="sr-only"
          multiple
          {...props}
          ref={fileInput}
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              setImageUrl(URL.createObjectURL(e.target.files[0]))
            }
          }}
        />
      </div>
    </div>
  )
}

export default AddImage
