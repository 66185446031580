import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Processor, { GenerateStrategy } from 'api/Retomagic'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchStyles from 'hooks/query/useFetchStyles'
import useFetchSuggestions from 'hooks/query/useFetchSuggestions'
import IdleStage from 'components/stages/generation/IdleStage'

import ProcessingStage from 'components/stages/generation/ProcessingStage'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import { LimitError } from 'types/Response'
import { fixedResolutions } from 'constants/advancedSettings'

import c from './Generation.module.scss'
import GenerationContainer, {
  AsideSlot,
  MainSlot,
} from '../GenerationControls/GenerationContainer/GenerationContainer'

export default function Generation() {
  const dispatch = useAppDispatch()

  const { stage } = useAppSelector((state) => state.stage)

  const navigate = useNavigate()

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const { data: stylesData } = useFetchStyles()

  const { data: suggestionsData } = useFetchSuggestions()

  const [chosenStyle, setChosenStyle] = useState<string | undefined>('')

  const [seconds, setSeconds] = useState<number | undefined>(0)
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)
  const [text, setText] = useState<string>('')
  const [resolution, setResolution] = useState<number>(1)
  const [guidanceScale, setGuidanceScale] = useState<number>(8)
  const [seed, setSeed] = useState<string | undefined>(undefined)
  const [steps, setSteps] = useState<number>(20)
  const [negativePrompt, setNegativePrompt] = useState<string>('')

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    try {
      dispatch(changeStage('processing'))

      const thisStyle = stylesData.find((elem) => elem.id === chosenStyle)

      const strategy = new GenerateStrategy(
        text,
        thisStyle?.title,
        thisStyle?.text,
        undefined,
        negativePrompt,
        steps,
        seed,
        guidanceScale,
        fixedResolutions[resolution].width || undefined,
        fixedResolutions[resolution].height || undefined,
      )

      const processor = new Processor(strategy)
      processor.on(
        ProcessorEvents.PROCESSED_ASSETS_CHANGED,
        handleProcessedAssetsChanged,
      )

      await processor.start()
      setSeconds(strategy.getSeconds())
      setGenerationProcessor(processor)
    } catch (error) {
      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        setText('')
        setChosenStyle('')
        dispatch(changeStage('idle'))
      }
      console.log(error)
    }
  }, [
    text,
    handleProcessedAssetsChanged,
    chosenStyle,
    stylesData,
    isAdvancedSettingsOpen,
    guidanceScale,
    seed,
    steps,
    resolution,
    negativePrompt,
  ])

  useEffect(() => {
    if (stage !== 'idle') return
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
  }, [stage])

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  useEffect(() => {
    return () => {
      if (stage !== 'processing') {
        dispatch(changeProcessedAssets([]))
        dispatch(changeCurrentAssetIndex(0))
        setGenerationProcessor(null)
        generationProcessor?.stop()
      }
    }
  }, [generationProcessor, stage])

  return (
    <GenerationContainer stage={stage}>
      <MainSlot>
        {stage === 'processing' && (
          <div className={c.processing}>
            <ProcessingStage seconds={seconds!} />
          </div>
        )}
        <Outlet />
      </MainSlot>
      <AsideSlot>
        <IdleStage
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          negativePrompt={negativePrompt}
          setNegativePrompt={setNegativePrompt}
          text={text}
          onTextChange={setText}
          onProcessingStart={handleProcessingStart}
          onExampleClick={onExampleClick}
          onStyleClick={onStyleClick}
          chosenStyle={chosenStyle}
          suggestions={suggestionsData}
          styles={stylesData}
          resolution={resolution}
          setResolution={setResolution}
          guidanceScale={guidanceScale}
          setGuidanceScale={setGuidanceScale}
          seed={seed}
          setSeed={setSeed}
          steps={steps}
          setSteps={setSteps}
        />
      </AsideSlot>
    </GenerationContainer>
  )
}
