import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Switch from '@material-ui/core/Switch'
import classNames from 'classnames'

import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/filled/filter_none.svg'

import { useAppDispatch } from 'hooks/store'
import { changeStage } from 'store/reducers/stageReducer'
import useCopy from 'hooks/useCopy'
import useSave from 'hooks/useSave'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { postPost } from 'api'
import { BaseAsset, Status } from 'types/Response'

import Lightbox from 'react-image-lightbox'
import FinishResultCard from '../../../FinishResultCard'
import Loader from '../../../common/Loader'
import c from './FinishStage.module.scss'

interface FinishStageProps {
  text?: string
  currentAssetUrl?: string | undefined
  currentAssetIndex: number
  assets?: BaseAsset[]
  sharedSlides: number[]
  setSharedSlides: React.Dispatch<React.SetStateAction<number[]>>
  onGenerateMore: () => void
  onGoBack: () => void
  isLoading: boolean
  onTextChange: (newText: string) => void
  generationStatus: Status
}

function FinishStage({
  text = '',
  isLoading = false,
  currentAssetUrl = undefined,
  currentAssetIndex,
  assets = [],
  sharedSlides,
  setSharedSlides,
  onGenerateMore,
  onGoBack,
  onTextChange,
  generationStatus,
}: FinishStageProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { data: user } = useFetchRequestMyProfile()
  const { id } = useParams()
  const queryClient = useQueryClient()

  const [assetsSuccess, setAssetsSuccess] = useState<BaseAsset[]>([])
  const [forSubs, setForSubs] = useState<boolean>(false)
  const [isFavorited, setIsFavorited] = useState<number[]>([])
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState<{
    [key: number]: boolean
  }>(assets.reduce((acc, asset) => ({ ...acc, [asset.id]: false }), {}))
  const [lightboxIndex, setLightboxIndex] = useState(0)
  const [isShared, setIsShared] = useState<{ [key: number]: boolean }>({})

  const handleForSubs = () => {
    setForSubs(!forSubs)
  }

  useEffect(() => {
    assets.forEach((elem, index) => {
      if (
        elem.status === 'success' &&
        !assetsSuccess.find((el) => el?.id === elem?.id)
      ) {
        setAssetsSuccess((prev) => {
          const newArr = [...prev]
          newArr[index] = elem
          return newArr
        })
      }
    })
  }, [assets])

  const [firstProcessedAssetIndex, setFirstProcessedAssetIndex] = useState<
    number | undefined
  >(undefined)
  const [currentSlide, setCurrentSlide] = useState<number>(currentAssetIndex)

  const { isSaved, handleSave } = useSave(assets?.[currentSlide]?.url)
  const { isCopied, handleCopy } = useCopy(text)

  const mutation = useMutation<void, Error, number>((assetId) => {
    return postPost({ assets: [assetId!], subscribers_only: forSubs })
  })

  const shareHandler = () => {
    mutation.mutateAsync(assets?.[currentSlide]?.id).then(() => {
      queryClient.invalidateQueries([`request`, id])
      queryClient.refetchQueries([`infinite-profilePosts-${user?.id}`])
    })
    setSharedSlides((prev) => [...prev, currentSlide])
  }

  const variationsHandler = () => {
    dispatch(changeStage('idle'))
    navigate(`/magic-variations/${assets?.[currentSlide]?.id}`)
  }

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon style={{ fill: 'currentColor' }} />
    )
  }, [isCopied])

  const handleTextChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    onTextChange(e.currentTarget.value)
  }

  useEffect(() => {
    if (firstProcessedAssetIndex) return
    const processedAsset = assets?.findIndex((a) => a.url)
    if (processedAsset) {
      setFirstProcessedAssetIndex(processedAsset)
    }
  }, [assets])

  const isThisSlideShared = useMemo(
    () => sharedSlides.includes(currentSlide),
    [sharedSlides, currentSlide],
  )

  const onRepeat = () => {
    setAssetsSuccess([])
    onGenerateMore()
  }

  const handleImageLoaded = useCallback((assetId: number) => {
    setIsImageLoaded((prevState) => ({ ...prevState, [assetId]: true }))
  }, [])

  const handleFullScreenOpen = (index: number) => {
    setLightboxIndex(index)
    setIsLightboxOpen(true)
  }

  const renderAssets = useMemo(() => {
    return assets.map((asset, index) => (
      <FinishResultCard
        key={asset.id || `asset-${index}`}
        asset={asset}
        index={index}
        isVideo
        isImageLoaded={isImageLoaded}
        handleImageLoaded={handleImageLoaded}
        handleFullScreenOpen={handleFullScreenOpen}
        shareHandler={shareHandler}
        isShared={isShared}
        variationsHandler={variationsHandler}
      />
    ))
  }, [
    assets,
    isImageLoaded,
    handleFullScreenOpen,
    shareHandler,
    isShared,
    variationsHandler,
  ])

  return (
    <div
      className={classNames({
        [c.finishingStage]: !isLoading,
        [c.loadingStage]: isLoading,
      })}
    >
      {isLoading && assets ? (
        <div className={c.loader}>
          <Loader />
        </div>
      ) : (
        <div className={c.content}>
          {generationStatus !== 'pending' && renderAssets}
        </div>
      )}
    </div>
  )
}

export default FinishStage
