import { useQuery } from '@tanstack/react-query'
import { getSuggestionsForMixer } from 'api'
import { Suggestion } from 'types/Generation'

const useFetchSuggestionsForMixer = () => {
  return useQuery<Suggestion[]>(
    ['suggestionsForMixer'],
    () => {
      return getSuggestionsForMixer()
    },
    { initialData: [] },
  )
}

export default useFetchSuggestionsForMixer
