import React from 'react'

import { Suggestion, Style } from 'types/Generation'
import { EnhancementResponse } from 'types/Response'
import Container from 'components/common/Container'
import GenerationStyles from 'components/GenerationStyles'
import AdvancedSettings from 'components/AdvancedSettings'

import c from './IdleStage.module.scss'
import RequestGenerationContainer from '../../../RequestGenerationContainer'

interface IdleStageProps {
  text: string
  onTextChange: (newText: string) => void
  suggestions?: Suggestion[]
  styles?: Style[]
  chosenStyle: string | undefined
  generationHistoryData?: EnhancementResponse[]
  chosenHistory?: number
  imageUrl: string | null | undefined
  strength: number
  setStrength: (value: any) => void
  onProcessingStart: () => void
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleHistoryClick: (id: number | undefined) => void
  onImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  guidanceScale: number
  setGuidanceScale: (newIndex: number) => void
  seed: string | undefined
  setSeed: (newSeed: string) => void
  steps: number
  setSteps: (newStep: number) => void
  negativePrompt: string
  setNegativePrompt: (newPrompt: string) => void
  isAdvancedSettingsOpen: boolean
  setIsAdvancedSettingsOpen: (prev: boolean) => void
  isImage: boolean
  onDeleteImage: () => void
  // openConceptsModal: () => void
}

function IdleStage({
  text,
  suggestions = [],
  styles = [],
  chosenStyle,
  generationHistoryData = [],
  chosenHistory = undefined,
  imageUrl,
  strength,
  setStrength,
  onTextChange,
  onProcessingStart,
  onExampleClick,
  onStyleClick,
  handleHistoryClick,
  onImageChange,
  guidanceScale,
  setGuidanceScale,
  seed,
  setSeed,
  steps,
  setSteps,
  negativePrompt,
  setNegativePrompt,
  isAdvancedSettingsOpen,
  setIsAdvancedSettingsOpen,
  isImage,
  onDeleteImage,
}: // openConceptsModal
IdleStageProps) {
  return (
    <Container flex>
      <div className={c.content}>
        <RequestGenerationContainer
          generationTextAreaVisible
          photoSelectionVisible
          onDeleteImage={onDeleteImage}
          imageUrl={imageUrl}
          generationHistoryData={generationHistoryData}
          chosenHistory={chosenHistory}
          handleHistoryClick={handleHistoryClick}
          onImageChange={onImageChange}
          negativePrompt={negativePrompt}
          setNegativePrompt={setNegativePrompt}
          negativePromptVisible
          suggestions={suggestions}
          promptTipsVisible
          text={text}
          isImage={isImage}
          onTextChange={onTextChange}
          onProcessingStart={onProcessingStart}
          onExampleClick={onExampleClick}
        />
        <AdvancedSettings
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          guidanceScale={guidanceScale}
          setGuidanceScale={setGuidanceScale}
          power={strength}
          setStrength={setStrength}
          guidanceScaleSelectionVisible
          seedSelectionVisible
          stepsSelectionVisible
          powerSelectionVisible
          seed={seed}
          setSeed={setSeed}
          steps={steps}
          setSteps={setSteps}
        />
        <GenerationStyles
          chosenStyle={chosenStyle}
          styles={styles}
          onStyleClick={onStyleClick}
        />
      </div>
    </Container>
  )
}

export default IdleStage
