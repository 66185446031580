/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-alert */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { NavLink, useNavigate } from 'react-router-dom'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'

import { ReactComponent as ArrowIcon } from '@material-design-icons/svg/round/expand_more.svg'

import { useTranslation } from 'react-i18next'

import VerifiedIcon from 'components/VerifiedIcon'

import { Img } from 'react-image'

import { toast } from 'react-toastify'

import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import Loader from 'components/common/Loader'
import useModal from 'hooks/useModal'
import { setLogInModalOpen } from 'store/reducers/modalReducer'
// import { unlike } from 'api'
import {
  adminDeleteEverywhere,
  likeRequest,
  unlikeRequest,
  deletePost,
  adminHidePost,
  adminUnHidePost,
} from 'api'
import ReactTimeAgo from 'react-time-ago'
import { PostFeed } from 'types/Post'
import IconButton from 'components/common/IconButton'
import useCopy from 'hooks/useCopy'
import FullScreenIcon from 'components/icons/FullScreenIcon'
import { useAppSelector } from 'hooks/store'

import UnLikeIcon from 'components/icons/UnLikeIcon'
import LikeIcon from 'components/icons/LikeIcon'
import CopyIcon from 'components/icons/CopyIcon'
import ViewsIcon from 'components/icons/ViewsIcon'
import LightBox from 'components/LightBox'

import SettingsPost from 'components/SettingsPost'

import c from './PostContent.module.scss'
import CommentIcon from '../icons/CommentIcon'

interface PostProps {
  post: PostFeed
  imgClassName?: string
  withLink?: boolean
  trending?: boolean
  feed?: boolean
}

function PostsContent({
  post,
  imgClassName = '',
  withLink = false,
  trending = false,
  feed = false,
}: PostProps) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const language = useAppSelector((state) => state.settings.language)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isLiked, setIsLiked] = useState<boolean>(post.is_liked)
  const [likesCount, setLikesCount] = useState<number>(post.likes_count)
  const { isCopied, handleCopy } = useCopy(post.assets?.[0]?.source)

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const toggleText = useCallback(() => {
    setIsExpanded((prev) => !prev)
  }, [])

  const handleImageLoaded = useCallback(() => {
    setIsImageLoaded(true)
  }, [])

  const { t } = useTranslation()

  const { data: user } = useFetchRequestMyProfile()
  const mutationLike = useMutation<void, Error>(() => {
    return likeRequest(post.id!)
  })
  const mutationUnLike = useMutation<void, Error>(() => {
    return unlikeRequest(post.id!)
  })
  const mutationDeleteByAdmin = useMutation<void, Error>(() => {
    return adminDeleteEverywhere(post.id!)
  })
  const mutationHide = useMutation<void, Error>(() => {
    return adminHidePost(post.id!)
  })
  const mutationUnhide = useMutation<void, Error>(() => {
    return adminUnHidePost(post.id!)
  })
  const mutationDelete = useMutation<void, Error>(() => {
    return deletePost(post.id!)
  })
  const { logInModalOpen } = useAppSelector((state) => state.modal)
  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon className={c.icon} style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon />
    )
  }, [isCopied])

  const handleFullScreenOpen = () => {
    setIsLightboxOpen(true)
  }

  const handleFullScreenClose = () => {
    setIsLightboxOpen(false)
  }

  const likeHandler = () => {
    if (user?.id !== post.user.id) {
      if (isLiked) {
        setLikesCount((prev) => prev - 1)
        setIsLiked(false)
        mutationUnLike.mutateAsync().then(() => {
          queryClient.invalidateQueries(['profile', post.id])
          queryClient.refetchQueries([`infinite-profilePosts-${post.user.id}`])
        })
      } else {
        setLikesCount((prev) => prev + 1)
        setIsLiked(true)
        mutationLike.mutateAsync().then(() => {
          queryClient.invalidateQueries(['profile', post.id])
          queryClient.refetchQueries([`infinite-profilePosts-${post.user.id}`])
        })
      }
    } else {
      toast.error("You can't like your own post")
    }
  }

  const deleteEverywhere = () => {
    const result = window.confirm('Are you sure want to delete this post?')
    if (result) {
      mutationDeleteByAdmin.mutate()
      toast.success('Everything went well')
    }
  }

  const deleteYourPost = () => {
    const result = window.confirm('Are you sure want to delete this post?')
    if (result) {
      mutationDelete.mutate()
      toast.success('Everything went well')
    }
  }

  const hideTrends = () => {
    const result = window.confirm('Are you sure want to hide this post?')
    if (result) {
      mutationHide.mutate()
      toast.success('Everything went well')
    }
  }

  const truncateText = (text: string) => {
    return text.length > 100 ? `${text.substring(0, 100)}... ` : text
  }

  const unhideTrends = () => {
    const result = window.confirm('Are you sure want to unhide this post?')
    if (result) {
      mutationUnhide.mutate()
      toast.success('Everything went well')
    }
  }

  return (
    <div className={c.post} style={{ zIndex: isLightboxOpen ? 1000 : 'auto' }}>
      <div className={c.postTop}>
        <div className={c.postUser}>
          <div className={c.avatarWrapper}>
            {post.user.avatar && (
              <img className={c.avatar} src={post.user.avatar} alt="" />
            )}
          </div>
          <div className={c.nicknameWrapper}>
            <p className={c.nickname}>
              {post.user.nickname}{' '}
              {post.user.is_paid_subscription && <VerifiedIcon />}
            </p>
            {post.created_at && (
              <p className={c.date}>
                <ReactTimeAgo
                  date={new Date(post.created_at)}
                  locale={language === 'en' ? 'en-US' : 'ru'}
                />
              </p>
            )}
          </div>
          <NavLink
            className={c.link}
            to={`/profile/${post.user.nickname}`}
            end
          />
        </div>

        {user && (
          <div className={c.settingsPost}>
            <SettingsPost
              userData={user}
              postData={post}
              delete={deleteYourPost}
              unhide={unhideTrends}
              hide={hideTrends}
              deleteByAdmin={deleteEverywhere}
            />
          </div>
        )}
      </div>
      <div
        className={`${
          trending || feed || post.assets?.[0].source_type === 'video'
            ? c.postImgWrapperTrending
            : c.postImgWrapper
        } ${imgClassName}`}
      >
        {post.assets?.[0].source_type === 'video' && post.assets?.[0].url && (
          <video
            src={post.assets?.[0].url}
            className={c.postImg}
            controls
            loop
          />
        )}
        {post.assets?.[0].source_type !== 'video' && post.assets?.[0].url && (
          <div className={c.imgWrapper}>
            <div className={c.postImgContainer}>
              <Img
                src={post.assets?.[0].url}
                className={c.postImg}
                loader={<Loader />}
                onLoad={handleImageLoaded}
              />
              <button
                type="button"
                onClick={handleFullScreenOpen}
                className={c.wrapper}
              >
                {isImageLoaded && (
                  <div className={c.fullScreenContainer}>
                    <div className={c.iconSetting}>
                      <FullScreenIcon />
                    </div>
                  </div>
                )}
              </button>
            </div>
            <NavLink to={`/trending-post/${post.id}`} end className={c.link} />
          </div>
        )}

        {isLightboxOpen && post.assets?.[0].url && (
          <LightBox
            imageUrl={post.assets?.[0].url}
            onCloseRequest={handleFullScreenClose}
          />
        )}
      </div>
      <div className={c.postBottom}>
        {post.assets?.[0]?.source_type !== 'image' && (
          <>
            <div className={c.text}>
              <span>
                {isExpanded
                  ? post.assets?.[0]?.source
                  : truncateText(post.assets?.[0]?.source)}
              </span>{' '}
              {post.assets?.[0]?.source.length > 100 && (
                <button
                  type="button"
                  className={c.buttonTextContainer}
                  onClick={toggleText}
                >
                  {isExpanded ? (
                    <span className={c.buttonText}>
                      {t('general.post.hideText')}
                    </span>
                  ) : (
                    <span className={c.buttonText}>
                      {t('general.post.showText')}
                    </span>
                  )}
                </button>
              )}
            </div>
            <IconButton
              className={c.postCopy}
              disabled={isCopied}
              onClick={handleCopy}
            >
              {copyIcon}
            </IconButton>
          </>
        )}
      </div>
      <div className={c.postTags}>
        {post.handler === 'William_Henry_Harrison' && (
          <div className={c.textStyleContainer}>
            <span className={c.textStyleSub}>{t('feed.dalle3')}</span>
          </div>
        )}
        {post.is_related && (
          <div className={c.textStyleContainer}>
            <span className={c.textStyleSub}>{t('feed.recommended')}</span>
          </div>
        )}
        {post.assets?.[0]?.style && post.assets?.[0]?.style !== 'undefined' && (
          <div className={c.textStyleContainer}>
            <span className={c.textStyle}>{post.assets?.[0]?.style}</span>
          </div>
        )}
      </div>
      <div className={c.postFeedBack}>
        <div className={c.toolsFeedback}>
          <div
            className={c.postToolButton}
            onClick={!user?.id ? openLogInModal : likeHandler}
            // @TODO не нужный функционал, требующий от eslint
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <div className={c.postToolButtonIcon}>
              <UnLikeIcon className={c.iconSetting} />
              <div className={`${c.favoredIcon} ${isLiked ? c.favored : ''}`}>
                <LikeIcon className={c.iconSetting} />
              </div>
            </div>
            <p className={c.postToolButtonCount}>{likesCount}</p>
          </div>
          {post.assets?.[0]?.source_type !== 'video' && (
            <button
              type="button"
              className={c.postToolButton}
              onClick={() => {
                navigate(`/trending-post/${post.id}/comments`)
              }}
            >
              <div className={c.postToolButtonIcon}>
                <CommentIcon className={c.iconSetting} />
              </div>
              <p className={c.postToolButtonCount}>{post.comments_count}</p>
            </button>
          )}
        </div>
        <div className={c.postViews}>
          {`${post.views_count} `} <ViewsIcon />
        </div>
      </div>
    </div>
  )
}

export default PostsContent
