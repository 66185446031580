import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchRequest from 'hooks/query/useFetchRequest'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import Processor, { GenerateStrategy } from 'api/Retomagic'

import DallE3Strategy from 'api/Retomagic/Processor/Strategies/DallE3Strategy'
import FinishStage from 'components/stages/dallE3/FinishStage'
import ProcessingStage from 'components/stages/generation/ProcessingStage'
import Container from 'components/common/Container'

import c from './DallE3FinishPage.module.scss'

function DallE3FinishPage() {
  const { stage, processedAssets, currentAssetIndex } = useAppSelector(
    (state) => state.stage,
  )
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [text, setText] = useState<string | undefined>('')
  const [seconds, setSeconds] = useState<number | undefined>(0)
  const [sharedSlides, setSharedSlides] = useState<number[]>([])

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const { requestId } = useParams()
  const { data, isLoading } = useFetchRequest({
    requestId,
  })
  useEffect(() => {
    if (sharedSlides.length > 0) setSharedSlides([])
  }, [requestId])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/generation/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    if (!text) return
    dispatch(changeStage('processing'))

    const strategy = new DallE3Strategy(
      text,
      '',
      '',
      data?.assets[0]?.settings?.width!,
      data?.assets[0]?.settings?.height!,
      requestId,
    )
    const processor = new Processor(strategy)
    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )
    await processor.repeat()
    setSeconds(strategy.getSeconds())
    setGenerationProcessor(processor)
  }, [text, handleProcessedAssetsChanged, data])

  const handleGoBack = useCallback(() => {
    dispatch(changeStage('idle'))
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
    setText('')
    navigate('/dall-e-3')
  }, [])

  useEffect(() => {
    setText(data?.assets?.[0]?.source)
  }, [])

  // const currentAssetUrl = useMemo(() => {
  //   const currentAsset = processedAssets[currentAssetIndex]
  //   return currentAsset?.url
  // }, [processedAssets, currentAssetIndex])

  useEffect(() => {
    return () => {
      if (stage !== 'processing') {
        generationProcessor?.stop()
      }
    }
  }, [generationProcessor, stage])

  return (
    <div className={c.generationFinishPage}>
      {stage === 'idle' && (
        <div className={c.container}>
          <FinishStage
            text={text}
            isLoading={isLoading}
            // currentAssetUrl={currentAssetUrl}
            currentAssetIndex={currentAssetIndex}
            assets={data?.assets}
            sharedSlides={sharedSlides}
            setSharedSlides={setSharedSlides}
            onTextChange={setText}
            onGenerateMore={handleProcessingStart}
            onGoBack={handleGoBack}
            generationStatus={data?.status || 'pending'}
          />
        </div>
      )}
    </div>
  )
}

export default DallE3FinishPage
