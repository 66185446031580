import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import LightBox from 'components/LightBox'
import { useAppDispatch } from 'hooks/store'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import useSave from 'hooks/useSave'
import useCopy from 'hooks/useCopy'
import { BaseAsset, Status } from 'types/Response'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addToFavorites, postPost } from 'api'
import { changeStage } from 'store/reducers/stageReducer'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/filled/filter_none.svg'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import Loader from '../common/Loader'
import c from './GenerationFinish.module.scss'
import FinishResultCard from '../FinishResultCard'

interface GenerationFinishProps {
  currentAssetIndex: number
  text?: string
  assets?: BaseAsset[]
  onGoBack: () => void
  sharedSlides: number[]
  onGenerateMore: () => void
  isLoading: boolean
  setSharedSlides: React.Dispatch<React.SetStateAction<number[]>>
  onTextChange: (newText: string) => void
  generationStatus: Status
}

function GenerationFinish({
  currentAssetIndex,
  onGoBack,
  isLoading,
  assets = [],
  text = '',
  onGenerateMore,
  onTextChange,
  sharedSlides,
  setSharedSlides,
  generationStatus,
}: GenerationFinishProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { data: user } = useFetchRequestMyProfile()

  const [currentSlide, setCurrentSlide] = useState<number>(currentAssetIndex)
  const [isFavorited, setIsFavorited] = useState<number[]>([])
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const { isSaved, handleSave } = useSave(assets?.[currentSlide]?.url)
  const { isCopied, handleCopy } = useCopy(text)
  const [isImageLoaded, setIsImageLoaded] = useState<{
    [key: number]: boolean
  }>(assets.reduce((acc, asset) => ({ ...acc, [asset.id]: false }), {}))
  const [lightboxIndex, setLightboxIndex] = useState(0)
  const [isShared, setIsShared] = useState<{ [key: number]: boolean }>({})
  const [forSubs, setForSubs] = useState<boolean>(false)

  const { pathname } = useLocation()

  const handleForSubs = () => {
    setForSubs(!forSubs)
  }

  const handleTextChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    onTextChange(e.currentTarget.value)
  }

  const mutationShare = useMutation<void, Error, number>((assetId) => {
    return postPost({ assets: [assetId!], subscribers_only: forSubs })
  })

  const mutationAddToFavorites = useMutation<void, Error>(() => {
    return addToFavorites(assets?.[currentSlide]?.id)
  })

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon style={{ fill: 'currentColor' }} />
    )
  }, [isCopied])

  const shareHandler = (assetId: number) => {
    mutationShare
      .mutateAsync(assetId)
      .then(() => {
        queryClient.invalidateQueries([`request`, id])
        queryClient.refetchQueries([`infinite-profilePosts-${user?.id}`])
      })
      .then(() => {
        setIsShared((prev) => ({ ...prev, [assetId]: true }))
        toast.success('Everything went well')
      })
    setSharedSlides((prev) => [...prev, currentSlide])
  }

  const handleAddToFavorites = () => {
    if (isFavorited.includes(currentSlide)) return

    mutationAddToFavorites
      .mutateAsync()
      .then(() => setIsFavorited((prev) => [...prev, currentSlide]))
  }

  const variationsHandler = (assetId: number) => {
    dispatch(changeStage('idle'))
    navigate(`/magic-variations/${assetId}`)
  }

  const handleImageLoaded = useCallback((assetId: number) => {
    setIsImageLoaded((prevState) => ({ ...prevState, [assetId]: true }))
  }, [])

  const handleFullScreenOpen = (index: number) => {
    setLightboxIndex(index)
    setIsLightboxOpen(true)
  }

  const handleFullScreenClose = () => {
    setIsLightboxOpen(false)
  }

  const moveToNext = () => {
    setLightboxIndex((prevIndex) =>
      prevIndex === assets.length - 1 ? 0 : prevIndex + 1,
    )
  }

  const moveToPrev = () => {
    setLightboxIndex((prevIndex) =>
      prevIndex === 0 ? assets.length - 1 : prevIndex - 1,
    )
  }

  useEffect(() => {
    const mainContent = document.getElementById('generationContainerMain')
    if (mainContent) {
      if (isLightboxOpen) {
        mainContent.style.zIndex = '100'
      } else {
        mainContent.style.zIndex = 'auto'
      }
    }
  }, [isLightboxOpen])

  const renderAssets = useMemo(() => {
    return assets.map((asset, index) => (
      <FinishResultCard
        key={asset.id}
        asset={asset}
        index={index}
        isImageLoaded={isImageLoaded}
        handleImageLoaded={handleImageLoaded}
        handleFullScreenOpen={handleFullScreenOpen}
        shareHandler={shareHandler}
        isShared={isShared}
        variationsHandler={variationsHandler}
      />
    ))
  }, [
    assets,
    isImageLoaded,
    handleFullScreenOpen,
    shareHandler,
    isShared,
    variationsHandler,
  ])

  return (
    <div
      className={classNames({
        [c.finishingStage]: !isLoading,
        [c.loadingStage]: isLoading,
      })}
    >
      {isLoading && assets ? (
        <div className={c.loader}>
          <Loader />
        </div>
      ) : (
        <div className={c.content}>
          {generationStatus !== 'pending' && renderAssets}
          {isLightboxOpen && assets?.[lightboxIndex]?.url && (
            <LightBox
              imageUrl={assets[lightboxIndex].url || 'defaultImg'}
              onCloseRequest={handleFullScreenClose}
              nextUrl={assets[(lightboxIndex + 1) % assets.length].url}
              prevUrl={
                assets[(lightboxIndex + assets.length - 1) % assets.length].url
              }
              onMovePrevRequest={moveToPrev}
              onMoveNextRequest={moveToNext}
              sourceUrl={
                pathname.includes('magic-variations')
                  ? assets[lightboxIndex].source_url
                  : ''
              }
            />
          )}
        </div>
      )}
    </div>
  )
}

export default GenerationFinish
