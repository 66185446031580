/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useFetchRequestProfile from 'hooks/query/useFetchRequestProfile'
import { useInView } from 'react-intersection-observer'
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  adminHideTrends,
  adminUnHideTrends,
  followRequest,
  unFollowRequest,
} from 'api'
import { setLogInModalOpen } from 'store/reducers/modalReducer'
import useModal from 'hooks/useModal'
import { useAppSelector } from 'hooks/store'
import Container from 'components/common/Container'
import { toast } from 'react-toastify'
import SettingIcon from 'components/icons/SettingIcon'
import { ReactComponent as SettingsIcon } from '@material-design-icons/svg/round/more_horiz.svg'
import VerifiedIcon from 'components/VerifiedIcon'
import { useMutation } from '@tanstack/react-query'
import Loader from 'components/common/Loader'
import useInfiniteFetchProfilePosts from 'hooks/query/useInfiniteFetchProfilePosts'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'

import PostsContent from 'components/PostsContent'

import c from './ProfilePage.module.scss'
import 'swiper/css'
import 'swiper/css/effect-cards'
import 'swiper/css/pagination'
import MobileHeader from '../../components/MobileHeader'

function ProfilePage() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { nickname } = useParams()

  const { pathname } = useLocation()

  const { data: myProfileData, isLoading: profileIsLoading } =
    useFetchRequestMyProfile()

  const isMyProfile = useMemo(
    () => nickname === myProfileData?.nickname,
    [nickname, myProfileData?.nickname],
  )

  const { data: userData } = useFetchRequestProfile({ user: nickname })

  const { ref, inView } = useInView()

  const [adminPanel, setAdminPanel] = useState<boolean>(false)

  const mutationHide = useMutation<void, Error>(() => {
    return adminHideTrends(userData?.id!)
  })
  const mutationUnhide = useMutation<void, Error>(() => {
    return adminUnHideTrends(userData?.id!)
  })

  const hideTrends = () => {
    const result = window.confirm('Are you sure want to block this user?')
    if (result) {
      mutationHide.mutate()
      toast.success('Everything went well')
    }
  }

  const unhideTrends = () => {
    const result = window.confirm('Are you sure want to unblock this user?')
    if (result) {
      mutationUnhide.mutate()
      toast.success('Everything went well')
    }
  }

  const { logInModalOpen } = useAppSelector((state) => state.modal)
  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)

  const {
    data: infinitePostsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchProfilePosts({ id: userData?.id, take: 8 })

  const postsData = useMemo(() => {
    if (!infinitePostsData) return []
    return infinitePostsData.pages.flatMap((p) => p)
  }, [infinitePostsData])

  useEffect(() => {
    if (isLoading) return
    fetchNextPage()
  }, [inView])

  const [isFollowed, setIsFollowed] = useState(userData?.is_subscribed)

  const mutationFollow = useMutation<void, Error>(() => {
    return followRequest(userData?.id!)
  })

  const mutationUnFollow = useMutation<void, Error>(() => {
    return unFollowRequest(userData?.id!)
  })

  const handleOpenChat = useCallback(() => {
    navigate(`/chats/${userData?.id}`)
  }, [userData])

  useEffect(
    () => setIsFollowed(userData?.is_subscribed),
    [userData?.is_subscribed],
  )
  const followHandler = () => {
    if (isFollowed) {
      setIsFollowed(false)
      mutationUnFollow.mutate()
    } else {
      setIsFollowed(true)
      mutationFollow.mutate()
    }
  }

  const activePage = (page: string) => {
    const array = pathname.split('/')
    const name = array[array.length - 1]
    return page === name
  }

  return (
    <div className={c.profilePage}>
      <MobileHeader
        heading={userData?.nickname}
        isOnGoBack
        isUserPremium={userData?.is_paid_subscription}
      />
      <Container fluid>
        <div className={c.content}>
          <div className={c.profile}>
            <div className={c.information}>
              <div className={c.avatarWrapper}>
                {userData?.avatar && (
                  <img className={c.avatar} src={userData?.avatar} alt="" />
                )}
              </div>
              <div className={c.user}>
                <div className={c.nicknameWrapper}>
                  <p className={c.nickname}>
                    {userData?.surname && <span>{userData?.surname}</span>}
                    {userData?.name && (
                      <span className={c.verifiedName}>
                        {userData?.name}
                        {userData.is_paid_subscription && <VerifiedIcon />}
                      </span>
                    )}
                    {!userData?.name && !userData?.surname && (
                      <span>{userData?.nickname}</span>
                    )}
                  </p>
                  {userData?.description && (
                    <p className={c.description}>{userData.description}</p>
                  )}
                  <div className={c.wrapperSettings}>
                    {isMyProfile && (
                      <NavLink
                        to="/settings"
                        end
                        className={`${c.settingContainer} ${c.settingsLink}`}
                      >
                        <div className={c.settingContent}>
                          <SettingIcon className={c.settingsIcon} />
                          {t('settings.heading')}
                        </div>
                      </NavLink>
                    )}
                    {myProfileData?.role === 'admin' && (
                      <div className={c.adminContainer}>
                        <button
                          type="button"
                          onClick={() => setAdminPanel(!adminPanel)}
                          className={c.admin}
                        >
                          <SettingsIcon
                            fill="#D1D1D6"
                            className={c.settingsIcon}
                          />
                        </button>
                        {adminPanel && (
                          <div className={c.adminPanel}>
                            {userData?.is_trending_blocked ? (
                              <button
                                type="button"
                                className={c.deleteAction}
                                onClick={unhideTrends}
                              >
                                Unhide trends
                              </button>
                            ) : (
                              <button
                                type="button"
                                className={c.deleteAction}
                                onClick={hideTrends}
                              >
                                Hide trends
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {!isMyProfile && !profileIsLoading && (
                  <div className={c.profileActions}>
                    {/* <button
                      type="button"
                      className={`${c.follow} ${isFollowed ? c.followed : ''}`}
                      onClick={!myProfileData ? openLogInModal : handleOpenChat}
                    >
                      {t('aiChat.messageInput')}
                    </button> */}
                    <button
                      type="button"
                      className={`${c.follow} ${isFollowed ? c.followed : ''}`}
                      onClick={!myProfileData ? openLogInModal : followHandler}
                    >
                      {mutationFollow.isLoading ? (
                        t('general.loading')
                      ) : (
                        <>
                          {isFollowed && t('profile.unFollow')}
                          {!isFollowed && t('profile.follow')}
                        </>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className={c.rightPart}>
              <div className={c.statistics}>
                <div className={c.statistic}>
                  <div className={c.value}>
                    {userData?.subscriptions_count || 0}
                  </div>
                  <div className={c.title}>{t('profile.following')}</div>
                  {isMyProfile && (
                    <NavLink to="/subscriptions" end className={c.link} />
                  )}
                </div>
                <div className={c.statistic}>
                  <div className={c.value}>
                    {userData?.subscribers_count || 0}
                  </div>
                  <div className={c.title}>{t('profile.followers')}</div>
                  {isMyProfile && (
                    <NavLink to="/subscribers" end className={c.link} />
                  )}
                </div>
                <div className={c.statistic}>
                  <div className={c.value}>{userData?.likes_count || 0}</div>
                  <div className={c.title}>{t('profile.likes')}</div>
                </div>
              </div>
              {!isMyProfile && (
                <button
                  type="button"
                  className={`${c.follow} ${isFollowed ? c.followed : ''} ${
                    c.followMobile
                  }`}
                  onClick={followHandler}
                >
                  {mutationFollow.isLoading ? (
                    t('general.loading')
                  ) : (
                    <>
                      {isFollowed && t('profile.unFollow')}
                      {!isFollowed && t('profile.follow')}
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
          {isMyProfile && (
            <div className={c.tabsWrapper}>
              <div className={c.tabsMain}>
                <div className={c.tabContainer}>
                  <Link
                    className={`${c.tabLink} ${
                      activePage(nickname || '') ? c.activeTab : ''
                    }`}
                    to={`/profile/${nickname}`}
                  >
                    {t('profile.myPosts')}
                  </Link>
                </div>
                <div className={c.tabContainer}>
                  <Link
                    className={`${c.tabLink} ${
                      activePage('liked') ? c.activeTab : ''
                    }`}
                    to={`/profile/${nickname}/liked`}
                  >
                    {t('profile.likedPosts')}
                  </Link>
                </div>
                <div className={c.tabContainer}>
                  <Link
                    className={`${c.tabLink} ${
                      activePage('archive') ? c.activeTab : ''
                    }`}
                    to={`/profile/${nickname}/archive`}
                  >
                    {t('profile.archive')}
                  </Link>
                </div>
                <div className={c.tabContainer}>
                  <Link
                    className={`${c.tabLink} ${
                      activePage('albums') ? c.activeTab : ''
                    }`}
                    to={`/profile/${nickname}/albums`}
                  >
                    {t('profile.concepts')}
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activePage(nickname || '') && (
            <div className={c.columnsContainer}>
              {postsData.map((postData) => {
                return <PostsContent key={postData.id} post={postData} />
              })}

              {!isLoading && !isFetchingNextPage && hasNextPage && (
                <div ref={ref} />
              )}
              {isFetchingNextPage && (
                <div className={c.loaderWrapper}>
                  <div className={c.loader}>
                    <Loader />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Outlet />
      </Container>
    </div>
  )
}

export default ProfilePage
