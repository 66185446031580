import React, { useCallback, useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useTranslation } from 'react-i18next'
import Modal from '../../common/Modal'
import AuthForm from '../../AuthForm'
import { UserRequest } from '../../../types/User'
import { putProfile } from '../../../api'
import useLogOut from '../../../hooks/query/auth/useLogOut'
import c from './UpdateUserModal.module.scss'
import useFetchRequestMyProfile from '../../../hooks/query/useFetchRequestMyProfile'

interface UpdateUserModalProps {
  open: boolean
  onClose: () => void
}

export default function UpdateUserModal({
  open,
  onClose,
}: UpdateUserModalProps) {
  const [userName, setUserName] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const { data: user } = useFetchRequestMyProfile()

  const { mutate: logOut } = useLogOut()

  const handleLogOut = useCallback(() => {
    logOut()
    onClose()
  }, [logOut])

  const onSuccess = () => {
    queryClient.refetchQueries(['myProfile'])
    onClose()
    setUserName('')
    setDescription('')
  }

  const mutation = useMutation<void, Error, UserRequest>(
    (userFields) => {
      return putProfile(userFields!)
    },
    {
      onSuccess,
    },
  )

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (user) {
        mutation.mutate({
          nickname: user.nickname,
          name: userName,
          description,
        })
      }
    },
    [userName, description],
  )

  return (
    <Modal
      onClose={onClose}
      open={open}
      screen="updateUserModal"
      heading={t('modals.general.profileUpdate')}
    >
      <AuthForm
        screen="userModal"
        userName={userName}
        description={description}
        submitText={t('modals.general.updateProfile')}
        isSubmitting={mutation.isLoading}
        onUserNameChange={setUserName}
        onDescriptionChange={setDescription}
        onSubmit={handleSubmit}
        handleLogOut={handleLogOut}
      />
    </Modal>
  )
}