import i18n from 'i18n/config'
import store from 'store'
import Handlers from 'types/Handlers'
import { BaseAsset, BaseResponse, EnhancementResponse } from 'types/Response'
import { RequestGeneration } from 'types/FormDataObject'
import { auth } from 'utils/firebase'
import { Style, Suggestion } from 'types/Generation'
import { toast } from 'react-toastify'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''

export const postRequestEnhancement = async (
  formData: FormData,
  handler: `${Handlers}`,
  headers: HeadersInit = {},
  subscribers_only: boolean = false,
): Promise<BaseResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  formData.append('subscribers_only', subscribers_only.toString())

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(
    `${endpoint}/v2/requests/${handler.toLowerCase()}`,
    {
      method: 'POST',
      body: formData,
      headers: {
        'X-Device-Id': deviceId,
        'x-language': language,
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
        ...headers,
      },
    },
  )

  if (!result.ok) {
    const errorData = await result.json()
    toast.error(
      `${i18n.t('LIMIT_GENERATION', { limit: errorData.data.limit })}`,
    )
    throw errorData
  }

  return result.json()
}

export const postRequestNeuroArt = async (
  dataObject: RequestGeneration,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/john_adams`, {
    method: 'POST',
    body: JSON.stringify(dataObject),
    headers: {
      'Content-Type': 'application/json',
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    const errorData = await result.json()
    toast.error(
      `${i18n.t('LIMIT_GENERATION', { limit: errorData.data.limit })}`,
    )
    throw errorData
  }

  return result.json()
}

export const postRequestDalle3 = async (
  dataObject: RequestGeneration,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/William_Henry_Harrison`, {
    method: 'POST',
    body: JSON.stringify(dataObject),
    headers: {
      'Content-Type': 'application/json',
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    const errorData = await result.json()
    toast.error(
      `${i18n.t('LIMIT_GENERATION', { limit: errorData.data.limit })}`,
    )
    throw errorData
  }

  return result.json()
}

export const postRequestMixer = async (
  formData: FormData,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/andrew_jackson`, {
    method: 'POST',
    body: formData,
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    const errorData = await result.json()
    toast.error(
      `${i18n.t('LIMIT_GENERATION', { limit: errorData.data.limit })}`,
    )
    throw errorData
  }

  return result.json()
}

export const postRequestVariations = async (
  formData: FormData,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/james_madison`, {
    method: 'POST',
    body: formData,
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    const errorData = await result.json()
    toast.error(
      `${i18n.t('LIMIT_GENERATION', { limit: errorData.data.limit })}`,
    )
    throw errorData
  }

  return result.json()
}

export const postRepeat = async (
  requestId: string,
  text: string,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/${requestId}/repeat`, {
    method: 'POST',
    body: JSON.stringify({ text }),
    headers: {
      'Content-Type': 'application/json',
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const postRequestVideoArt = async (
  dataObject: RequestGeneration,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/john_quincy_adams`, {
    method: 'POST',
    body: JSON.stringify(dataObject),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const postRequestInpaint = async (
  formData: FormData,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/martin_van_buren`, {
    method: 'POST',
    body: formData,
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getRequestById = async (
  requestId: number,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/${requestId}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  const dataJson = await result.json()

  if (dataJson.error) {
    if (dataJson.error === 'content_policy_violation') {
      throw new Error(i18n.t('error.forbidden'))
    }
    if (dataJson.error === 'rate_limit_exceeded') {
      throw new Error(i18n.t('error.rateLimitExceeded'))
    }
    throw new Error(i18n.t('error.api'))
  }

  return dataJson
}

export const getAssetById = async (
  assetId: number,
  headers: HeadersInit = {},
): Promise<BaseAsset> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/assets/${assetId}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getRequests = async (
  limit: number,
  offset: number,
  sourceType?: `${'image' | 'video' | 'text' | 'hybrid' | 'user_name'}`,
  handler?: `${Handlers}`,
  headers: HeadersInit = {},
): Promise<BaseResponse[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/requests'

  const paramsObject = {
    offset: offset.toString(),
    limit: limit.toString(),
    status: 'success',
    ...(handler && { handler }),
    ...(sourceType && { sourceType }),
  }

  const params = new URLSearchParams(paramsObject).toString()

  const result = await fetch(`${endpoint}/${domain}?${params}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getGeneration = async (
  headers: HeadersInit = {},
): Promise<EnhancementResponse[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const params = new URLSearchParams({
    source_type: 'text',
    limit: '8',
    handler: 'John_Adams',
  }).toString()

  const domain = 'v2/requests'

  const result = await fetch(`${endpoint}/${domain}?${params}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}
export const getStyles = async (
  headers: HeadersInit = {},
): Promise<Style[]> => {
  const result = await fetch('https://data.magicly.ai/styles', {
    method: 'GET',
    headers: {
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getSuggestions = async (
  headers: HeadersInit = {},
): Promise<Suggestion[]> => {
  const result = await fetch('https://data.magicly.ai/suggestions', {
    method: 'GET',
    headers: {
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getSuggestionsForMixer = async (
  headers: HeadersInit = {},
): Promise<Suggestion[]> => {
  const result = await fetch('https://data.magicly.ai/suggestions/neuromixer', {
    method: 'GET',
    headers: {
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const deleteRequest = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/requests'

  const result = await fetch(`${endpoint}/${domain}/${postId}`, {
    method: 'DELETE',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const deleteAsset = async (
  assetId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/assets'

  const result = await fetch(`${endpoint}/${domain}/${assetId}`, {
    method: 'DELETE',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const getSuggestionsForNegativePrompt = async (
  headers: HeadersInit = {},
): Promise<Suggestion[]> => {
  const result = await fetch(
    'https://data.magicly.ai/suggestions/negative_prompt',
    {
      method: 'GET',
      headers: {
        ...headers,
      },
    },
  )

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const deleteRequests = async (
  ids: number[],
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/requests'

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'DELETE',
    body: JSON.stringify({ ids }),
    headers: {
      'Content-Type': 'application/json',
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}
