/* eslint-disable import/prefer-default-export */
import store from 'store'
import i18n from 'i18n/config'
import { auth } from 'utils/firebase'
import { UserRequest, UserResponse } from 'types/User'
import { NotificationsResponse } from 'types/Notifications'
import { ConceptsItem, ConceptsResponse } from 'types/Concepts'
import { SearchResponse } from '../types/Search'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''

export const getMyProfile = async (
  headers: HeadersInit = {},
): Promise<UserResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile'

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getSearchResult = async (
  userText: string,
  LIMIT: number,
  offset: number,
  headers: HeadersInit = {},
): Promise<SearchResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v2/search?`

  const params = new URLSearchParams({
    offset: offset.toString(),
    limit: LIMIT.toString(),
    text: userText,
  }).toString()

  const url = `${endpoint}/${domain}${params}`

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getProfile = async (
  user: string | number,
  headers: HeadersInit = {},
): Promise<UserResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'

  const result = await fetch(`${endpoint}/${domain}/${user}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const putProfile = async (
  body: UserRequest,
  headers: HeadersInit = {},
): Promise<void> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/profile`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    const data = await result.json()
    if (data.message.code === '23505') {
      throw new Error(`${i18n.t('formError.nickname.uniqueNickname')}`)
    }
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const followRequest = async (
  userId: string,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'

  const path = 'subscribe'

  const result = await fetch(`${endpoint}/${domain}/${userId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const unFollowRequest = async (
  userId: string,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'

  const path = 'unsubscribe'

  const result = await fetch(`${endpoint}/${domain}/${userId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const getNotifications = async (
  limit: number,
  offset: number,
  headers: HeadersInit = {},
): Promise<NotificationsResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/notifications'

  const params = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
  }).toString()

  const result = await fetch(`${endpoint}/${domain}?${params}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const readNotification = async (
  notificationsIds: number[],
  headers: HeadersInit = {},
): Promise<void> => {
  if (notificationsIds.length === 0) {
    return
  }

  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()
  const body = JSON.stringify({ ids: notificationsIds })

  const domain = 'v2/profile/notifications'

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'POST',
    body,
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      'Content-Type': 'application/json',
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const getSubscriptions = async (
  userId: string,
  headers: HeadersInit = {},
): Promise<UserResponse[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'
  const path = 'subscriptions'

  const result = await fetch(`${endpoint}/${domain}/${userId}/${path}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getSubscribers = async (
  userId: string,
  headers: HeadersInit = {},
): Promise<UserResponse[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'
  const path = 'subscribers'

  const result = await fetch(`${endpoint}/${domain}/${userId}/${path}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const postConcept = async (
  files: File[],
  name: string,
  headers: HeadersInit = {},
): Promise<void> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/concepts'

  const formData = new FormData()

  files.forEach((file) => {
    formData.append(`files`, file)
  })

  formData.append('name', name)

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'POST',
    body: formData,
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const getConcepts = async (
  limit: number,
  offset: number,
  headers: HeadersInit = {},
): Promise<ConceptsResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/concepts'

  const params = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
  }).toString()

  const result = await fetch(`${endpoint}/${domain}?${params}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
  return result.json()
}

export const getConcept = async (
  conceptId: number,
  headers: HeadersInit = {},
): Promise<ConceptsItem> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/concepts'

  const result = await fetch(`${endpoint}/${domain}/${conceptId}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const deleteConcept = async (
  conceptId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/concepts'

  const result = await fetch(`${endpoint}/${domain}/${conceptId}`, {
    method: 'DELETE',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}
export const deleteConceptItems = async (
  conceptId: number,
  file_ids: number[],
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/concepts'
  const path = 'items'

  const result = await fetch(`${endpoint}/${domain}/${conceptId}/${path}`, {
    method: 'DELETE',
    body: JSON.stringify({ file_ids }),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const postItemsForConcept = async (
  conceptId: number,
  file_ids: File[],
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/concepts'
  const path = 'items'

  const formData = new FormData()

  file_ids.forEach((file) => {
    formData.append(`files`, file)
  })

  const result = await fetch(`${endpoint}/${domain}/${conceptId}/${path}`, {
    method: 'POST',
    body: formData,
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const editConcept = async (
  conceptId: number,
  name: string,
  headers: HeadersInit = {},
): Promise<void> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/concepts'

  const result = await fetch(`${endpoint}/${domain}/${conceptId}`, {
    method: 'PUT',
    body: JSON.stringify({ name }),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const addToFavorites = async (
  assetId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/concepts'
  const path = 'favorite/items'

  const result = await fetch(`${endpoint}/${domain}/${path}/${assetId}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const postStripeSubscribe = async (
  price: string,
  headers: HeadersInit = {},
): Promise<{ url: string }> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v2/stripe`

  const result = await fetch(`${endpoint}/${domain}/${price}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const deleteAccount = async (headers: HeadersInit = {}) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v2/profile`

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'DELETE',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}
