import i18n from 'i18n/config'
import store from 'store'
import { CommentsResponse } from 'types/Comments'
import { LikedPostResponse, PostFeed } from 'types/Post'
import { auth } from 'utils/firebase'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''

export const getFeed = async (
  type: 'subscriptions' | 'trending',
  conclusion: 'video_only' | 'images_only' | 'all' = 'images_only',
  page: number = 1,
  take: number = 14,
  viewedPosts?: number[],
  headers: HeadersInit = {},
): Promise<PostFeed[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v2/feed/${type}`

  const params = new URLSearchParams({
    offset: ((page - 1) * take).toString(),
    limit: take.toString(),
    conclusion,
  }).toString()

  let url = `${endpoint}/${domain}?${params}`
  if (type === 'trending') {
    url += `&exceptions=${viewedPosts?.toString()}`
  }

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getTrending = async (
  headers: HeadersInit = {},
): Promise<PostFeed[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/feed/trending'

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const postPost = async (
  body: { assets: number[]; subscribers_only?: boolean } = {
    assets: [],
    subscribers_only: false,
  },
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/posts`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const getPost = async (
  postId: string,
  headers: HeadersInit = {},
): Promise<PostFeed> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const result = await fetch(`${endpoint}/${domain}/${postId}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getMyPosts = async (
  user: string | number,
  page: number = 1,
  take: number = 4,
  headers: HeadersInit = {},
): Promise<PostFeed[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'
  const path = 'posts'

  const params = new URLSearchParams({
    offset: ((page - 1) * take).toString(),
    limit: take.toString(),
  }).toString()

  const result = await fetch(
    `${endpoint}/${domain}/${user}/${path}?${params}`,
    {
      method: 'GET',
      headers: {
        'X-Device-Id': deviceId,
        'x-language': language,
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
        ...headers,
      },
    },
  )

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const likeRequest = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const path = 'like'

  const result = await fetch(`${endpoint}/${domain}/${postId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const unlikeRequest = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const path = 'unlike'

  const result = await fetch(`${endpoint}/${domain}/${postId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const sendViewedArray = async (
  postIds: number[],
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const path = 'views'

  const result = await fetch(`${endpoint}/${domain}/${path}`, {
    method: 'POST',
    body: JSON.stringify({ ids: postIds }),
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      'Content-Type': 'application/json',
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const deletePost = async (postId: number, headers: HeadersInit = {}) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const result = await fetch(`${endpoint}/${domain}/${postId}`, {
    method: 'DELETE',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const getRecommendations = async (
  postId: number,
  headers: HeadersInit = {},
): Promise<PostFeed[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'
  const path = 'related'

  const result = await fetch(`${endpoint}/${domain}/${postId}/${path}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const viewedRecommendations = async (
  post_id: number,
  related_posts: number[],
  selected_post: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'
  const path = 'related/stats'

  const { userAgent } = navigator

  const result = await fetch(`${endpoint}/${domain}/${post_id}/${path}`, {
    method: 'POST',
    body: JSON.stringify({
      related_posts,
      selected_post,
      meta: {
        interface: 'web',
        'User-Agent': userAgent,
        Date: new Date().toISOString(),
      },
    }),
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      'Content-Type': 'application/json',
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const getComments = async (
  postId: number,
  limit: number,
  offset: number,
  headers: HeadersInit = {},
): Promise<CommentsResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/comments'

  const params = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
  }).toString()

  const result = await fetch(`${endpoint}/${domain}/${postId}?${params}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getComment = async (
  commentId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/comments'

  const result = await fetch(
    `${endpoint}/${domain}/${commentId}/comment/data`,
    {
      method: 'GET',
      headers: {
        'X-Device-Id': deviceId,
        'x-language': language,
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
        ...headers,
      },
    },
  )

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const createComment = async (
  postId: number,
  assetId: number,
  parentId?: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/comments'

  const result = await fetch(`${endpoint}/${domain}/${postId}`, {
    method: 'POST',
    body: JSON.stringify({ assetId, parentId }),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const likeComment = async (
  commentId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/comments'
  const path = 'like'

  const result = await fetch(`${endpoint}/${domain}/${commentId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const unlikeComment = async (
  commentId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/comments'
  const path = 'unlike'

  const result = await fetch(`${endpoint}/${domain}/${commentId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const deleteComment = async (
  commentId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/comments'

  const result = await fetch(`${endpoint}/${domain}/${commentId}`, {
    method: 'DELETE',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const getCommentsOnComment = async (
  postId: number,
  commentId: number,
  limit: number,
  offset: number,
  headers: HeadersInit = {},
): Promise<CommentsResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/comments'

  const params = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
  }).toString()

  const result = await fetch(
    `${endpoint}/${domain}/${postId}/${commentId}?${params}`,
    {
      method: 'GET',
      headers: {
        'X-Device-Id': deviceId,
        'x-language': language,
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
        ...headers,
      },
    },
  )

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getLikedPosts = async (
  limit: number,
  offset: number,
  headers: HeadersInit = {},
): Promise<LikedPostResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile/likes'

  const params = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
  }).toString()
  const result = await fetch(`${endpoint}/${domain}?${params}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}
