import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Style } from 'types/Generation'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import ShortArrow from '../icons/ShortArrow'
import c from './GenerationStyles.module.scss'
import useFetchRequest from '../../hooks/query/useFetchRequest'

interface GenerationStylesProps {
  styles?: Style[]
  chosenStyle: string | undefined
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  modal?: boolean
}

function GenerationStyles({
  chosenStyle,
  styles = [],
  onStyleClick,
  modal = false,
}: GenerationStylesProps) {
  const { t } = useTranslation()

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [startIndex, setStartIndex] = useState(6)
  const [currentStyles, setCurrentStyles] = useState<Style[]>([])

  let chosenStyleObj = styles.find(
    (elem) => elem.id === chosenStyle || elem.title === chosenStyle,
  )

  const openContent = useCallback(() => {
    setIsVisible(!isVisible)
  }, [isVisible])

  const { requestId } = useParams()

  const { data } = useFetchRequest({
    requestId,
  })

  useEffect(() => {
    if (data && data.assets && data.assets.length > 0 && data.assets[0].style) {
      const styleIdOrTitle = data.assets[0].style
      if (typeof styleIdOrTitle === 'string') {
        chosenStyleObj = styles.find(
          (elem) => elem.id === styleIdOrTitle || elem.title === styleIdOrTitle,
        )
      } else {
        chosenStyleObj = styleIdOrTitle
      }
    }
  }, [data, styles])

  const addMoreStyles = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    const endOffset = startIndex + 6
    setCurrentStyles([...currentStyles, ...styles.slice(startIndex, endOffset)])

    if (startIndex + 6 > styles.length) {
      setStartIndex(0)
      return
    }
    setStartIndex(startIndex + 6)
  }

  useEffect(() => {
    setCurrentStyles(styles.slice(0, 6))
    setStartIndex(6)
  }, [styles])

  return (
    <button
      type="button"
      onClick={openContent}
      className={classNames({
        [c.containerStyles]: true,
        [c.containerStylesUnActive]: !isVisible,
      })}
    >
      <div className={c.wrapperStyles}>
        <div className={c.leftPart}>
          <div className={c.stylesTitle}>
            {t('stages.generation.idle.text')}
          </div>
        </div>
        <div className={c.rightPart}>
          <div className={c.displayStyle}>
            {chosenStyleObj ? chosenStyleObj.title : 'No style selected'}
          </div>
          <ShortArrow
            className={classNames(c.arrowIcon, {
              [c.arrowIconOpen]: !isVisible,
            })}
          />
        </div>
      </div>
      <CSSTransition
        in={isVisible}
        timeout={100}
        classNames={{
          enter: c.settingsEnter,
          enterActive: c.settingsEnterActive,
          exit: c.settingsExit,
          exitActive: c.settingsExitActive,
        }}
        mountOnEnter
        unmountOnExit
      >
        {/* eslint-disable-next-line */}
        <div
          tabIndex={0}
          role="button"
          className={c.styles}
          onClick={(e) => e.stopPropagation()}
        >
          {styles?.length > 0 &&
            currentStyles?.map((elem) => (
              <button
                type="button"
                className={`${c.stylesVariant} ${
                  chosenStyle === elem?.id ? c.active : ''
                } ${chosenStyle === elem?.title ? c.active : ''}`}
                key={`styles-${elem.id}`}
                value={elem.id}
                onClick={onStyleClick}
              >
                <img src={elem.cover} className={c.picture} alt="" />
                <p className={c.text}>{elem.title}</p>
              </button>
            ))}
        </div>
      </CSSTransition>
      {startIndex !== 0 && isVisible && (
        // eslint-disable-next-line
        <div
          className={c.moreStyles}
          tabIndex={0}
          role="button"
          onClick={addMoreStyles}
        >
          Показать еще
        </div>
      )}
    </button>
  )
}

export default GenerationStyles
